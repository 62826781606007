import React, { useState, useEffect } from 'react';
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react';
import {
  FaWallet,
  FaGift,
  FaGamepad,
  FaUserFriends,
  FaTasks,
  FaClock,
  FaEthereum,
} from 'react-icons/fa';
import tonLogoImage from '../styles/img/ton-logo.png';
import '../styles/Wallet.css';
import { updateTONWalletAddress } from '../services/api';
import { toUserFriendlyAddress } from '@tonconnect/sdk';

const Wallet = () => {
  const [activeTab] = useState('ton');
  const [isUpdating, setIsUpdating] = useState(false);
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  useEffect(() => {
    const updateWallet = async () => {
      const wallet = tonConnectUI.account;
      if (tonConnectUI.connected && wallet?.address) {
        const rawAddress = wallet.address;
        console.log('Connected wallet raw address:', rawAddress);

        let friendlyAddress;
        try {
          friendlyAddress = toUserFriendlyAddress(rawAddress);
          console.log('Connected wallet friendly address:', friendlyAddress);
        } catch (e) {
          console.error('Error converting address:', e);
          return;
        }

        setIsUpdating(true);

        try {
          console.log('Sending update request with address:', friendlyAddress);
          await updateTONWalletAddress(friendlyAddress);
          console.log('TON wallet address updated successfully');
        } catch (error) {
          console.error('Error updating TON wallet address:', error);
        } finally {
          setIsUpdating(false);
        }
      }
    };

    updateWallet();
  }, [tonConnectUI.connected, tonConnectUI.account]);

  return (
    <div className="screen-3">
      <section className="frame-parent">
        <div className="frame-item">
          <img src={tonLogoImage} alt="TON Logo" className="ton-logo-image" />
          <div className="card-content">
            <div className="card-top">
              <div className="ton-logo">TON Wallet</div>
            </div>
            <div className="connect-button-wrapper">
              <TonConnectButton />
            </div>
            <div className="card-bottom">
              {tonConnectUI.connected && tonConnectUI.account && (
                <div className="wallet-provider">
                  {tonConnectUI.wallet?.imageUrl && (
                    <img
                      src={tonConnectUI.wallet.imageUrl}
                      alt={`${tonConnectUI.wallet.name} logo`}
                      className="provider-logo"
                    />
                  )}
                  <span className="provider-name">{tonConnectUI.wallet?.name}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {isUpdating && (
          <div className="updating-message">
            <p>Updating wallet address...</p>
          </div>
        )}
        <div className="airdrop-eligibility">
          <h2 className="airdrop-title">
            <FaGift /> Airdrop Eligibility
          </h2>
          <div className="eligibility-grid">
            <div className="eligibility-item">
              <div className="icon-wrapper">
                <FaClock className="eligibility-icon" color="#f6d83e" />
              </div>
              <div className="eligibility-content">
                <div className="title">Daily Login</div>
                <p>Log in daily to claim bonus rewards</p>
              </div>
            </div>
            <div className="eligibility-item">
              <div className="icon-wrapper">
                <FaGamepad className="eligibility-icon" color="#c699ff" />
              </div>
              <div className="eligibility-content">
                <div className="title">Game Earnings</div>
                <p>Play games to earn more coins</p>
              </div>
            </div>
            <div className="eligibility-item">
              <div className="icon-wrapper">
                <FaTasks className="eligibility-icon" color="#81d389" />
              </div>
              <div className="eligibility-content">
                <div className="title">Task Completion</div>
                <p>Complete social, on-chain, and partner tasks</p>
              </div>
            </div>
            <div className="eligibility-item">
              <div className="icon-wrapper">
                <FaUserFriends className="eligibility-icon" color="#f6d83e" />
              </div>
              <div className="eligibility-content">
                <div className="title">Friend Referrals</div>
                <p>Refer friends to earn coins and game passes</p>
              </div>
            </div>
            <div className="eligibility-item">
              <div className="icon-wrapper">
                <FaEthereum className="eligibility-icon" color="#c699ff" />
              </div>
              <div className="eligibility-content">
                <div className="title">
                  Miniverse NFTs <span className="optional-tag">Optional</span>
                </div>
                <p>Buy and hold Miniverse NFTs for additional airdrop allocation and exclusive benefits</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Wallet;