import React, { useState, useEffect } from 'react';
import { getUserNFTs } from '../../services/nftService';
import './MyNFTs.css'; 
import liteImage from '../../styles/img/lite.png';
import proImage from '../../styles/img/pro.png';

const Notification = () => {
  return (
    <div className="notification">
      <p>
        <strong>Important:</strong> NFT withdrawal will be available after the Airdrop concludes. You can claim rewards by visiting official website
      </p>
    </div>
  );
};

const MyNFTs = () => {
  const [nfts, setNFTs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const userNFTs = await getUserNFTs();
        // Group NFTs by nft_id and calculate total quantity
        const groupedNFTs = userNFTs.reduce((acc, nft) => {
          if (!acc[nft.nft_id]) {
            acc[nft.nft_id] = {
              ...nft.nfts,
              quantity: 0,
              token_ids: []
            };
          }
          acc[nft.nft_id].quantity += nft.quantity;
          acc[nft.nft_id].token_ids.push(nft.token_id);
          return acc;
        }, {});
        setNFTs(Object.values(groupedNFTs));
      } catch (error) {
        console.error('Error fetching user NFTs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, []);

  const getImageSource = (nftName) => {
    if (nftName.toLowerCase().includes('lite')) {
      return liteImage;
    } else if (nftName.toLowerCase().includes('pro')) {
      return proImage;
    }
    return nftName.image_url; // fallback to the nft.image_url if provided
  };

  if (loading) {
    return (
      <div className="skeleton-loader">
        <div className="skeleton-nft-item"></div>
        <div className="skeleton-nft-item"></div>
        <div className="skeleton-nft-item"></div>
      </div>
    );
  }

  return (
    <div className="my-nfts-container">
      <Notification />
      <div className="my-nfts">
        <h2>My NFT Collection</h2>
        {nfts.length === 0 ? (
          <div className="no-nfts">You don't own any NFTs yet.</div>
        ) : (
          <div className="nft-grid">
            {nfts.map((nft) => (
              <div key={nft.id} className="nft-item">
                <div className="nft-image-container">
                  <img src={getImageSource(nft.name)} alt={nft.name} className="nft-image" />
                </div>
                <h3>{nft.name}</h3>
                <p>Quantity: {nft.quantity}</p>
                <p className="token-ids">Token IDs: {nft.token_ids.join(', ')}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyNFTs;